import { API_URLS } from '../../config/API_URLS/api_urls';
import axiosInstance from './axiosInstance';
const baseURL = API_URLS?.baseURL;

const getApiCall = async (
  url,
  paramsData = {
    pathParams: {},
    queryParams: {},
    headers: {},
    isAuthorized: false,
  }
) => {
  try {
    // Replace path parameters in the URL if they exist
    if (
      paramsData?.pathParams &&
      Object.keys(paramsData.pathParams).length > 0
    ) {
      Object.keys(paramsData.pathParams).forEach((key) => {
        console.log('GET REQ PATH', paramsData.pathParams[key]);
        url = url + '/' + paramsData.pathParams[key];
      });

    }

    let requestUrl = baseURL + url;
    console.log("GET REQ URL", requestUrl);

    // Handle query parameters
    const queryParams = paramsData?.queryParams;
    if (queryParams && Object.keys(queryParams).length > 0) {
      requestUrl += `?`;
      Object.keys(queryParams).forEach((key) => {
        requestUrl += `${key}=${queryParams[key]}&`;
      });
      requestUrl = requestUrl.slice(0, -1); // Remove trailing '&'
    }

    // Handle headers and authorization
    let headers = { ...paramsData?.headers };
    if (paramsData?.isAuthorized) {
      headers.Authorization = `Bearer ${localStorage.getItem('token')}`;
    }

    // Make the GET request
    const res = await axiosInstance.get(requestUrl, { headers });
    if (
      paramsData?.pathParams &&
      Object.keys(paramsData.pathParams).length > 0
    ) {

      console.log("GET RES", res);
      return res;
    }
    return res;
  } catch (err) {
    return err;
  }
};

const postApiCall = async (
  url,
  reqBody,
  paramsData = { queryParams: {}, headers: {}, isAuthorized: false }
) => {
  try {
    let requestUrl = baseURL + url;
    const paramsLength = Object.values(paramsData?.queryParams);
    if (paramsLength.length) {
      requestUrl += `?`;
      Object.keys(paramsData?.queryParams).forEach((key) => {
        requestUrl += `${key}=${paramsData?.queryParams[key]}&`;
      });
      requestUrl = requestUrl.slice(0, -1);
    }
    if (paramsData?.headers || paramsData?.isAuthorized) {
      let headers = { ...paramsData?.headers };
      if (paramsData?.isAuthorized) {
        headers.Authorization = `Bearer ${localStorage.getItem('token')}`;
      }
      return await axiosInstance.post(requestUrl, reqBody, { headers });
    } else {
      return await axiosInstance.post(requestUrl, reqBody);
    }
  } catch (err) {
    console.log('Error', err);
    return err;
  }
};

const putApiCall = async (
  url,
  reqBody,
  paramsData = { queryParams: {}, headers: {}, isAuthorized: false }
) => {
  try {
    let requestUrl = baseURL + url;
    const paramsLength = Object.values(paramsData?.queryParams);
    if (paramsLength.length) {
      requestUrl += `?`;
      Object.keys(paramsData?.queryParams).forEach((key) => {
        requestUrl += `${key}=${paramsData?.queryParams[key]}&`;
      });
      requestUrl = requestUrl.slice(0, -1);
    }
    if (paramsData?.headers || paramsData?.isAuthorized) {
      let headers = { ...paramsData?.headers };
      if (paramsData?.isAuthorized) {
        headers.Authorization = `Bearer ${localStorage.getItem('token')}`;
      }
      return await axiosInstance.put(requestUrl, reqBody, { headers });
    } else {
      return await axiosInstance.put(requestUrl, reqBody);
    }
  } catch (err) {
    return err;
  }
};

const deleteApiCall = async (
  url,
  data = {}, // Accepts the data to be sent in the request body
  paramsData = { queryParams: {}, headers: {}, isAuthorized: false }
) => {
  try {
    let requestUrl = baseURL + url;

    // Handle query parameters if present
    const paramsLength = Object.values(paramsData?.queryParams).length;
    if (paramsLength) {
      requestUrl += `?`;
      Object.keys(paramsData?.queryParams).forEach((key) => {
        requestUrl += `${key}=${paramsData?.queryParams[key]}&`;
      });
      requestUrl = requestUrl.slice(0, -1);
    }

    // Handle headers and authorization
    let headers = { ...paramsData?.headers };
    if (paramsData?.isAuthorized) {
      headers.Authorization = `Bearer ${localStorage.getItem('token')}`;
    }

    // Send the DELETE request with the body
    return await axiosInstance.delete(requestUrl, {
      headers,
      data: data, // Include the data (body) in the DELETE request
    });
  } catch (err) {
    return err;
  }
};

export { getApiCall, postApiCall, putApiCall, deleteApiCall };
