import React, { useEffect } from 'react';
import { CustomTable } from '../../shared/Table';
import CustomTableHead from '../../shared/Table/TableHead';
import { TablePagination, TableBody, TableRow } from '@mui/material';
import { TableCellText, TableCellButton } from '../../shared/Table/TableCell';
import PanelHeader from '../../../components/shared/Header/PanalHeader';
import { tableHeader } from '../../../helpers/networks/admins';
import { getNetworkUsers, updateIsAdmin } from '../../../services/networks';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useState } from 'react';
import useUserData from '../../../hooks/UserData';
import ScreenLoader from '../../shared/ScreenLoader';
import Text from '../../shared/Text';
import { useSelector } from 'react-redux';
import c from '../table.module.scss'

const Admins = () => {
  const [adminData, setAdminData] = useState([]);

  const handleActionChange = (value, item) => {
    return;
  };

  const [pageNumber, setPageNumber] = useState({
    totalPages: 0,
    currentPage: 0,
    limit: 25,
  });

  const opPageChange = (e, page_number) => {
    setPageNumber({
      ...pageNumber,
      currentPage: page_number,
    });
    window.scrollTo(0, 0);
  };

  const setRowsPerPage = (row_page) => {
    setPageNumber({
      ...pageNumber,
      limit: row_page,
    });
    window.scrollTo(0, 0);
  };

  const { client_name } = useSelector((state) => state?.generalInformation);

  const queryClient = useQueryClient();
  const [search, setSearch] = useState('');
  const userData = useUserData();
  const [sort, setSort] = useState({ name: 'asc' });
  const [status, setStatus] = useState(null);
  const [initialLoading, setInitialLoading] = useState(true);

  const { mutate: updateAdminFn, isLoading } = useMutation(updateIsAdmin, {
    onSuccess: () => {
      queryClient.refetchQueries('getAdmins');
      queryClient.refetchQueries('getNetworkSummary');
    },
  });

  useEffect(() => {
    if (!initialLoading) {
      const timeOut = setTimeout(() => {
        setStatus(search);
        setPageNumber({
          ...pageNumber,
          currentPage: 0
        });
      }, 500);
      return () => clearTimeout(timeOut);
    }
  }, [search]);

  const updateAdmin = (data) => {
    updateAdminFn({
      id: data.id,
      is_admin: false,
      loggedInUserClientID: userData.id,
      user_id: data.user_id,
      client_name: client_name,
    });
  };

  const sortHandler = (sortCellName) => {
    setSort({
      ...sort,
      [sortCellName]: sort[sortCellName] === 'asc' ? 'desc' : 'asc',
    });
  };

  const { isFetching, refetch } = useQuery(
    ['getAdmins', sort, status, pageNumber.currentPage],
    () =>
      getNetworkUsers({
        type: 'admins',
        sort_by: `[["user_name","${sort.name}"]]`,
        search: search || "",
        page_number: pageNumber.currentPage + 1,
        limit: pageNumber.limit,
      }),
    {
      refetchOnMount: true,
      retry: false,
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        if (data?.registrants)
          setAdminData(data?.registrants);
        setInitialLoading(false);
      },
      onError: () => {
        setInitialLoading(false);
      },
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    refetch();
  }, [pageNumber.limit])

  return (
    <div className="w-100">
      <PanelHeader
        heading="Admins"
        isSearch={true}
        isSelect={false}
        setSearch={setSearch}
      />
      {isLoading || isFetching ? (
        <ScreenLoader />
      ) : adminData?.length !== 0 ? (
        <>
          <CustomTable classToOverride="w-100 px-4 my-2">
            <CustomTableHead
              headerData={tableHeader}
              sortHandler={sortHandler}
              sort={sort}
            />
            <TableBody>
              {adminData
                // .filter(({ user_name }) =>
                //   user_name?.toLowerCase().trim().includes(search)
                // )
                .map((admin) => (
                  <TableRow hover key={admin.id}>
                    <TableCellText text={admin.user_name} />
                    <TableCellText
                      text={
                        admin.neighborhood_details
                          ? admin.neighborhood_details?.name
                          : '-'
                      }
                    />
                    <TableCellText text={admin.email} />

                    <TableCellButton
                      btnName={'Remove Admin'}
                      onClick={() => updateAdmin(admin)}
                    />
                  </TableRow>
                ))}
            </TableBody>
          </CustomTable>
        </>
      ) : (
        <Text className="text-center mt-5 fs-4 font-weight-bold">
          No Record Found
        </Text>
      )}
      {isLoading || isFetching ? "" : (<div className="d-flex align-items-center justify-content-center py-5">
        <TablePagination
          className={c.tablePagination}
          component="div"
          count={-1}
          page={pageNumber?.currentPage}
          rowsPerPageOptions={[10, 25, 100]}
          rowsPerPage={pageNumber?.limit}
          onPageChange={(e, page) => {
            opPageChange(e, page);
          }}
          onRowsPerPageChange={(e) => {
            setRowsPerPage(e?.target?.value);
          }}
        />
      </div>)}
    </div>
  );
};

export default Admins;
