import { CustomTable } from '../../../shared/Table';
import CustomTableHead from '../../../shared/Table/TableHead';
import { TablePagination, TableBody, TableRow } from '@mui/material';
import {
  TableCellText,
  TableCellDropdown,
} from '../../../shared/Table/TableCell';
import PanelHeader from '../../../../components/shared/Header/PanalHeader';
import { neighborhoodTableHeader } from '../../../../helpers/networks/neighbourhoods';
import { useMutation, useQuery } from 'react-query';
import neighborhoodsCls from './neighbourhood.module.scss';
import {
  activeOrdeactive,
  getNeighborhoods,
} from '../../../../services/networks';
import classNames from 'classnames';
import ScreenLoader from '../../../shared/ScreenLoader';
import { useState } from 'react';

const NeighborhoodList = ({ setSelectedId, setShowMemebers }) => {
  const [sort, setSort] = useState({ name: 'asc' });

  const [pageNumber, setPageNumber] = useState({
    totalPages: 0,
    currentPage: 0,
    limit: 25,
  });

  const opPageChange = (e, page_number) => {
    setPageNumber({
      ...pageNumber,
      currentPage: page_number,
    });
    window.scrollTo(0, 0);
  };

  const setRowsPerPage = (row_page) => {
    setPageNumber({
      ...pageNumber,
      limit: row_page,
    });
    window.scrollTo(0, 0);
  };

  const { data, isFetching, refetch } = useQuery(
    ['neighborhoodList', sort, pageNumber],
    () =>
      getNeighborhoods({
        type: 'clients',
        page_number: pageNumber.currentPage + 1,
        limit: pageNumber.limit,
        sort_by: `[["user_name","${sort.name}"]]`,
      }),
    {
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        if (data?.registrants && data?.registrants.length !== 0)
          setPageNumber({
            ...pageNumber,
            totalPages: data?.total_pages || 0,
          });
      },
    }
  );

  const { mutate, isLoading: isMutating } = useMutation(
    (data) => activeOrdeactive(data),
    {
      onSuccess: refetch,
    }
  );

  const handleOnClick = (id) => () => {
    setSelectedId(id);
    setShowMemebers(true);
  };

  const sortHandler = (sortCellName) => {
    setSort({
      ...sort,
      [sortCellName]: sort[sortCellName] === 'asc' ? 'desc' : 'asc',
    });
  };

  if (isFetching || isMutating) return <ScreenLoader />;

  const handleAction = (value, id, item) => {
    if (value) {
      const data = {
        id: item.user_id,
        status: value === 'Active' ? true : false,
      };
      mutate(data);
    }
  };
  return (
    <div className="w-100">
      <PanelHeader heading="Neighborhoods" isSearch={false} isSelect={false} />
      <CustomTable classToOverride="w-100 px-4 mt-2">
        <CustomTableHead
          headerData={neighborhoodTableHeader}
          sort={sort}
          sortHandler={sortHandler}
        />
        <TableBody>
          {data.registrants.map((n) => (
            <TableRow key={n.id} hover>
              <TableCellText
                text={n.client_name}
                className={classNames(
                  'mb-0',
                  { 'pe-none': Number(n.user_count) < 1 },
                  neighborhoodsCls.cursorPointer
                )}
                onClick={handleOnClick(n.client_id)}
              />
              <TableCellText
                text={`${n.user_count} of ${n.max_licenses || 0}`}
              />
              <TableCellText text={n.user_name} />
              <TableCellText text={n.email} />
              <TableCellText text={n?.client_id} classToOverride="text-nowrap" />
              <TableCellDropdown
                btnName={n.is_user_account_active ? 'Active' : 'Inactive'}
                options={[
                  { id: 1, value: 'Active' },
                  { id: 0, value: 'Inactive' },
                ]}
                item={n}
                setValue={handleAction}
              />
            </TableRow>
          ))}
        </TableBody>
      </CustomTable>
      <div className="d-flex align-items-center justify-content-center py-5">
        <TablePagination
          className={neighborhoodsCls.tablePagination}
          component="div"
          count={-1}
          page={pageNumber?.currentPage}
          rowsPerPageOptions={[10, 25, 100]}
          rowsPerPage={pageNumber?.limit}
          onPageChange={(e, page) => {
            opPageChange(e, page);
          }}
          onRowsPerPageChange={(e) => {
            setRowsPerPage(e?.target?.value);
          }}
        />
      </div>
    </div>
  );
};
export default NeighborhoodList;
